import RollingBanner from '@/components/RollingBanner';
import { BaseBlockComponentProps } from '@/types/block';

type RollingBannerBlockProps = BaseBlockComponentProps<{
  text: string;
}>;

const RollingBannerBlock: RollingBannerBlockProps = ({ block }) => (
  <RollingBanner className="bg-[var(--backgroundColor)]" text={block.text} />
);
export default RollingBannerBlock;
